import React from "react";
import icon from "./icon.png";
import logo from "./logo.png";

function Logo() {
  return (
    <div className="text-center">
        <img className="h-36 m-auto inline" alt="" src={icon}/>
        <img className="h-20 m-auto inline" alt="" src={logo}/>
    </div>
  );
}

export default Logo;
